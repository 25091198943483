import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paper = makeShortcode("Paper");
const SingleImage = makeShortcode("SingleImage");
const GoTo = makeShortcode("GoTo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "encourage-good-sleep-habits"
    }}>{`Encourage good sleep habits`}</h1>
    <h3 {...{
      "id": "why-does-my-teenager-need-good-sleep-habits"
    }}>{`Why does my teenager need good sleep habits?`}</h3>
    <p>{`A good night’s sleep is important to help your teenager be at their best during the day. Good sleep habits help them to get a refreshing night’s sleep.`}</p>
    <Paper mdxType="Paper">
  <p>
    <b>
      Research<sup>5,6,7</sup> shows that:
    </b>
  </p>
  <p>Insomnia (difficulty falling asleep or staying asleep) is one of the most common risk factors for depression.</p>
  <p>Feeling tired during the day makes it hard to concentrate on school work.</p>
    </Paper>
    <h3 {...{
      "id": "what-are-good-sleep-habits"
    }}>{`What are good sleep habits?`}</h3>
    <ol>
      <li parentName="ol">{`Go to bed at the same time every day.`}</li>
      <li parentName="ol">{`Wind down before bedtime.`}</li>
      <li parentName="ol">{`Avoid caffeine late in the day.`}</li>
      <li parentName="ol">{`Create a restful bedroom.`}</li>
      <li parentName="ol">{`Don’t watch TV or use electronic devices in bed or in the hour before bed. The 'blue light' from electronic devices can make it harder to get to sleep.`}</li>
      <li parentName="ol">{`Avoid napping – it can make it harder to fall asleep at night!`}</li>
    </ol>
    <h3 {...{
      "id": "what-should-i-do-to-help-my-teenager-to-practise-good-sleep-habits"
    }}>{`What should I do to help my teenager to practise good sleep habits?`}</h3>
    <ul>
      <li parentName="ul">{`Encourage and support your teenager's efforts to follow the 6 good sleep habits above (e.g. agreeing on bedtimes, establishing rules about screen use).`}</li>
      <li parentName="ul">{`Practise them yourself.`}</li>
      <li parentName="ul">{`If sleep is a problem for your teenager, check if they are following each of the good sleep habits.`}</li>
    </ul>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m7/34.svg" alt="Sleeping on time" mdxType="SingleImage" />
    <sup>
  5,6,7. See <GoTo to="/appendix" mdxType="GoTo">Appendix</GoTo> page for full reference.
    </sup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      